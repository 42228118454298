import alloyAnalytics from '@telus/alloy-analytics'
import { LinkProps } from '../../blocks/core/Link/Link'
import { ButtonLinkProps } from '../../blocks/core/ButtonLink/ButtonLink'
import { ChevronLinkProps } from '../../blocks/core/ChevronLink/ChevronLink'

type Url = LinkProps['url'] | ButtonLinkProps['url'] | ChevronLinkProps['url']
type AnalyticsUrlUsage =
  | LinkProps['analyticsUrlUsage']
  | ButtonLinkProps['analyticsUrlUsage']
  | ChevronLinkProps['analyticsUrlUsage']

type LinkClickProps = {
  url: Url
  analyticsUrlUsage: AnalyticsUrlUsage
}

const alloyEvents = {
  linkClick: ({ url, analyticsUrlUsage }: LinkClickProps) => {
    if (analyticsUrlUsage !== 'Scroll-Bookmark' && analyticsUrlUsage !== 'Standard') return

    const linkName = `anchor_link-${url}`
    const linkClicks = { name: linkName, linkName }
    alloyAnalytics.event('linkClicks').webInteraction(linkClicks).fire()
  },
}

export default alloyEvents
