import alloyAnalytics from '@telus/alloy-analytics'
import { BaseModalProps } from '../../blocks/core/BaseModal/BaseModal'

type ModalOpenProps = Pick<BaseModalProps, 'id' | 'entryTitle'>
type ModalCloseProps = Pick<BaseModalProps, 'id' | 'entryTitle'>

const TOOL_TYPE = 'modal'

const alloyEvents = {
  modalOpen: ({ id, entryTitle }: ModalOpenProps) => {
    const toolSubmit = {
      name: 'modal_open',
      toolName: `modal-${entryTitle}`,
      toolType: TOOL_TYPE,
      toolUsageID: id,
      toolSelections: `modal_open-${entryTitle}`,
    }
    alloyAnalytics.event('toolSubmit').webInteraction(toolSubmit).fire()
  },
  modalClose: ({ id, entryTitle }: ModalCloseProps) => {
    const toolSubmit = {
      name: 'modal_close',
      toolName: `modal-${entryTitle}`,
      toolType: TOOL_TYPE,
      toolUsageID: id,
      toolSelections: `modal_close-${entryTitle}`,
    }
    alloyAnalytics.event('toolSubmit').webInteraction(toolSubmit).fire()
  },
}

export default alloyEvents
