import { useEffect, useState } from 'react'
import { eventActionTrigger as koodoEventActionTrigger } from '@telus/koodo-analytics-scripts'
import { ContentfulAnalyticsEventType, AnalyticsEventBasicV1 } from 'src/siteBuilder/utils/types/analytics'

/**
 * Helper function to check if the analytics event is valid and is not the
 * same as the previous event.
 *
 * NOTE: Make this check to ensure that we don't fire the same event multiple times.
 *
 * @param {AnalyticsEventV1} analyticsEvent
 * @param {AnalyticsEventV1} previousEvent
 * @returns {boolean}
 */
const isDifferentValidEvent = (analyticsEvent: AnalyticsEventBasicV1, previousEvent: AnalyticsEventBasicV1) => {
  const isValidEvent = analyticsEvent?.action && analyticsEvent?.name
  const isSameEvent = JSON.stringify(analyticsEvent) === JSON.stringify(previousEvent)
  return isValidEvent && !isSameEvent
}

/**
 * A utility to set the analytics event object inside window.dataLayer and window.adobe.dataLayer,
 * only if window exists.
 *
 * @param analyticsEvent
 * @param isAnalyticsTriggered
 */
const useKoodoAnalyticsEvent = (analyticsEvent: ContentfulAnalyticsEventType, isAnalyticsTriggered = false) => {
  const [prevEvent, setPrevEvent] = useState<AnalyticsEventBasicV1>(null)
  useEffect(() => {
    if (isAnalyticsTriggered) {
      const dataLayerEvent = {
        name: analyticsEvent?.fields?.eventName,
        action: analyticsEvent?.fields?.eventAction,
        details: analyticsEvent?.fields?.eventDetails,
      }

      if (
        isDifferentValidEvent(dataLayerEvent, prevEvent) &&
        typeof window === 'object' &&
        'dataLayer' in window // dataLayer will eventually be removed
      ) {
        window.dataLayer.event = dataLayerEvent
        koodoEventActionTrigger(dataLayerEvent)
        setPrevEvent(dataLayerEvent)
      }
    }
  }, [isAnalyticsTriggered, analyticsEvent])
}

export default useKoodoAnalyticsEvent
