import { Spacer as AlliumSpacer } from '@telus-uds/components-web'
import { pickDataAttrs } from '../../../utils/dataAttributes'
import styled from 'styled-components'

const StyledSpacerContainer = styled.div({
  display: 'block',
  flexBasis: '100%',
})

export type SpacerProps = {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

export const Spacer = ({
  xs = undefined,
  sm = undefined,
  md = undefined,
  lg = undefined,
  xl = undefined,
  ...rest
}: SpacerProps) => {
  return (
    <StyledSpacerContainer {...pickDataAttrs(rest)} className="spacer">
      <AlliumSpacer testID={'allium-spacer-testid'} space={{ xs, sm, md, lg, xl }} />
    </StyledSpacerContainer>
  )
}

export default Spacer
