import { useContext } from 'react'
import { SiteBuilderContext } from '../siteBuilder/renderer/context/SiteBuilderContext'
import { BaseModalProps } from '../siteBuilder/blocks/core/BaseModal/BaseModal'

const useModalClick = (url: string, baseModal: BaseModalProps) => {
  const { setActiveModal, modals } = useContext(SiteBuilderContext)
  const tryModalClick = (e: MouseEvent) => {
    if (baseModal) {
      e.preventDefault()
      setActiveModal(baseModal.id)
      return
    }

    // check if url is a hash
    if (url.startsWith('#')) {
      const id = modals[url]
      if (id) {
        e.preventDefault()
        setActiveModal(id)
        return
      }
    }
  }
  return { tryModalClick }
}

export default useModalClick
