export const intcmpDict = {
  businessBranch: {
    'telus.com': 'tcom',
    'koodo.com': 'kod',
    'TELUS Foundation': 'foundation',
  },
  lineofBusiness: {
    B2b: 'b2b',
    Mobility: 'mob',
    MyTelus: 'mt',
    'Home Solutions': 'hs',
    'Future Friendly Home': 'ffh',
    'Digital Commerce': 'dc',
    'Platform Tools': 'ptt',
    pe: 'pe',
    'Brand Experience': 'bx',
    'Smart Wear Security': 'sws',
    'Smart Home Security': 'shs',
    'Living Well Companion': 'lwc',
    'Telus Quebec': 'tq',
    'Vendor Pages': 'ven',
    'Home Solutions MyTelus': 'hs-mt',
    'B2B MyTelus': 'b2b-mt',
    mhs: 'mhs',
    'Social impact': 'si',
    'Social Impact Blog': 'si-b',
    About: 'about',
    Wise: 'wise',
    'Wise Blog': 'wise-b',
    'Pollinator Fund': 'pf',
    'Pollinator Fund Blog': 'pf-b',
    'Business (DRB)': 'bus',
    DRB: 'outages',
  },
  linkType: {
    Button: 'button',
    Icon: 'icon',
    Link: 'link',
    Banner: 'banner',
    Tile: 'tile',
    Carousels: 'carousels',
    Chevron: 'chevron',
    Image: 'image',
    Catalogue: 'catalogue',
    Toasts: 'toasts',
    Cont: 'cont',
    Stories: 'stories',
    Article: 'article',
    Quote: 'quote',
    Redirect: 'redirect',
  },
  position: {
    'Components placed in spot #1': '1',
    'Components placed in spot #2': '2',
    'Components placed in spot #3': '3',
    'Components placed in spot #4': '4',
    'Components placed in spot #5': '5',
    'Components placed in spot #6': '6',
    'Components placed in spot #7': '7',
    'Components placed in spot #8': '8',
    'Component placed on top of page': 'top',
    'Component placed in the middle of the page': 'middle',
    'Component placed in the bottom of the page': 'bottom',
    'Component placed left of the display': 'left',
    'Component placed right of the display': 'right',
  },
  businessSize: {
    'Small Business Solutions': 'smb',
    Commercial: 'tbs',
    Partner: 'part',
  },
}
