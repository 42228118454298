import { useEffect, useState } from 'react'
import { intcmpDict } from '../siteBuilder/utils/intcmpDictionary'

export type intcmpProps = {
  businessBranch?: string
  lineofBusiness?: string
  linkType?: string
  cta: string
  position?: string
  businessSize?: string
}

export const useIntcmpContent = (url = '', ctaText = '', intcmpRef) => {
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentUrl(location.pathname)
    }
  }, [currentUrl])

  const queryBuilder = (fields) => {
    const busBranchStr = fields.businessBranch && (intcmpDict.businessBranch[fields.businessBranch] as string)
    const loBStr = fields.lineofBusiness && (intcmpDict.lineofBusiness[fields.lineofBusiness] as string)
    const lTypeStr = fields.linkType && (intcmpDict.linkType[fields.linkType] as string)
    const ctaStr = ctaText && ctaText.replace(/ /g, '-').toLowerCase()
    const posStr = fields.position && (intcmpDict.position[fields.position] as string)
    const bSizeStr = fields.businessSize && (intcmpDict.businessSize[fields.businessSize] as string)
    const urlSplit = currentUrl.split('/')
    const currentSlug = urlSplit[urlSplit.length - 1]

    return [busBranchStr, loBStr, currentSlug, lTypeStr, ctaStr, posStr, bSizeStr].filter((a) => a).join('_')
  }

  const urlBuilder = () => {
    // checks if there are existing queries on the url and appends intcmp appropriately
    return url && url.indexOf('?') !== -1
      ? `${url}&INTCMP=${queryBuilder(intcmpRef.fields)}`
      : `${url}?INTCMP=${queryBuilder(intcmpRef.fields)}`
  }

  // checks if intcmp entry is referenced on link component
  // if it is, builds the new link url with the intcmp code - if not, returns the default url set in contentful
  return intcmpRef && Object.keys(intcmpRef).length > 0 ? urlBuilder() : url
}
